<template>
  <div slot="main">
    <b-row>
      <b-col v-if="isUpload" md="6">
        <b-form-file
          ref="file-input"
          v-model="kycFiles"
          placeholder="Choose a file..."
          drop-placeholder="Drop file here..."
          multiple
          @change="changeFile"
        />
      </b-col>
      <b-col md="6" class="text-right">
        <b-button v-if="isSave" class="mr-2" variant="primary" @click="saveFileList">
          Save
        </b-button>
        <b-button v-if="isSave" variant="outline-secondary" @click="cancel">
          Cancel
        </b-button>
      </b-col>
    </b-row>

    <b-table
      :items="kycFilesArray"
      :fields="fields"
      class="mb-0 mt-2 kyc-modal-table"
      responsive="sm"
    >
      <template #cell(No)="data">
        {{ data.index + 1 }}
      </template>
      <!-- <template #cell()="data">
        <div>
          <img :src="fileType(data.item.type)" alt="" class="kyc-modal-image">
        </div>
      </template> -->
      <template #cell(name)="data">
        <a :href="data.item.url" target="_blank" class="kyc-file-name">
          <img :src="fileType(data.item.type)" alt="" class="kyc-modal-image">
          {{ data.value }}
        </a>
      </template>
      <template #cell(operate)="data">
        <feather-icon
          v-if="isDelete"
          icon="Trash2Icon"
          size="20"
          class="mr-50 kyc-modal-icon"
          @click="deleteFile(data.item)"
        />
        <!-- <feather-icon
          icon="DownloadIcon"
          size="20"
          class="mr-50 kyc-modal-icon"
          @click="downloadFile(data.item)"
        /> -->
      </template>
    </b-table>
    <div class="mb-1">
      &nbsp;
    </div>
  </div>
</template>
<script>
import {
  BTable, BFormFile, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { showToast } from '@/libs/tool'
import useMerchantJs from './merchants'

const {
  uploadKycFile,
  // getCountryAndRegions,
} = useMerchantJs()

export default {
  components: {
    BTable,
    BFormFile,
    BRow,
    BCol,
    BButton,
  },
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
    isSave: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isUpload: {
      type: Boolean,
      default: false,
    },
    returnKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      kycFiles: [],
      kycFilesArray: [],
      // eslint-disable-next-line global-require
      docPng: require('@/assets/images/filetype/doc.png'),
      // eslint-disable-next-line global-require
      docxPng: require('@/assets/images/filetype/docx.png'),
      // eslint-disable-next-line global-require
      emptyFilePng: require('@/assets/images/filetype/empty-file.png'),
      // eslint-disable-next-line global-require
      jpegPng: require('@/assets/images/filetype/jpeg.png'),
      // eslint-disable-next-line global-require
      jpgPng: require('@/assets/images/filetype/jpg.png'),
      // eslint-disable-next-line global-require
      pdfPng: require('@/assets/images/filetype/pdf.png'),
      // eslint-disable-next-line global-require
      pngPng: require('@/assets/images/filetype/png.png'),
      // eslint-disable-next-line global-require
      xlsPng: require('@/assets/images/filetype/xls.png'),
      // eslint-disable-next-line global-require
      xlsxPng: require('@/assets/images/filetype/xlsx.png'),
    }
  },
  computed: {
    fields() {
      if (this.isDelete) {
        return [
          'No.',
          'name',
          'operate',
        ]
      }
      return [
        'No.',
        'name',
      ]
    },
  },
  mounted() {
    this.initKycFiles()
  },
  methods: {
    initKycFiles() {
      if (this.fileList.length === 0) return
      this.kycFilesArray = []
      this.kycFilesArray = this.getFileObject(this.fileList)
    },
    getFileObject(list) {
      if (list.length <= 0) return []
      const fileArray = []
      list.forEach(value => {
        const file = {}
        const nameIndex = value.lastIndexOf('/') + 1
        const typeIndex = value.lastIndexOf('.') + 1
        file.name = value.substring(nameIndex)
        file.url = value
        file.type = value.substring(typeIndex)
        fileArray.push(file)
      })
      // eslint-disable-next-line consistent-return
      return fileArray
    },
    changeFile(event) {
      const fileList = event.target.files
      this.uploadFile(fileList)
    },
    uploadFile(fileList) {
      if (!fileList || fileList.length <= 0) {
        showToast(this, 'Warning', 'No File Select')
        return
      }
      const params = new FormData()
      fileList.forEach(file => {
        params.append('files', file)
      })
      const that = this
      that.$showLoading()
      uploadKycFile(
        res => {
          that.$hideLoading()
          const { urlList } = res
          that.kycFilesArray = that.getFileObject(urlList).concat(that.kycFilesArray)
          this.unique(that.kycFilesArray)
          this.$refs['file-input'].reset()
          showToast(that, 'Success', 'Success')
        },
        fail => {
          that.$hideLoading()
          showToast(this, 'Warning', `${fail}`)
        },
        params,
      )
    },
    downloadFile(item) {
      const aFile = document.createElement('a')
      aFile.href = item.url
      aFile.target = '_blank'
      aFile.style.display = 'none'
      document.body.appendChild(aFile)
      aFile.click()
      aFile.remove()
    },
    deleteFile(item) {
      let deleteIndex
      this.kycFilesArray.forEach((file, index) => {
        if (file.name === item.name) {
          deleteIndex = index
        }
      })
      this.kycFilesArray.splice(deleteIndex, 1)
    },
    fileType(type) {
      if (type === 'doc') {
        return this.docPng
      } if (type === 'docx') {
        return this.docxPng
      } if (type === 'jpeg' || type === 'jpe') {
        return this.jpegPng
      } if (type === 'jpg') {
        return this.jpgPng
      } if (type === 'pdf') {
        return this.pdfPng
      } if (type === 'png') {
        return this.pngPng
      } if (type === 'xls') {
        return this.xlsPng
      } if (type === 'xlsx') {
        return this.xlsxPng
      }
      return this.emptyFilePng
    },
    getFiles() {
      if (this.returnKey) {
        // 转url数组
        return {
          [this.returnKey]: this.kycFilesArray.map(value => value.url),
        }
      }
      return {}
    },
    unique(arr) {
      if (!Array.isArray(arr)) {
        // console.log('type error!')
        return
      }
      const urls = []
      const array = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < arr.length; i++) {
        if (urls.indexOf(arr[i].url) === -1) {
          urls.push(arr[i].url)
          array.push(arr[i])
        }
      }
      // eslint-disable-next-line consistent-return
      return array
    },
    saveFileList() {
      this.$emit('saveFileList', this.getFiles())
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>
<style lang="scss" scoped>
.kyc-file-name {
  width: 400px;
  color:#333333;
  display: inline-block;
}
.kyc-modal-icon {
  margin-right: 15px!important;
  cursor: pointer;
}
.kyc-modal-image {
  width: 22px;
  height: auto;
}
.kyc-modal-table {
  max-height: 475px;
  overflow-y: scroll;
}
</style>
