<template>
  <div class="m-2">
    <b-card title="Basic Info">
      <b-row>
        <b-col md="6">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Merchant ID :
            </dt>
            <dd class="col-sm-7">
              {{ merchantNo }}
            </dd>
          </dl>
        </b-col>
        <b-col />
      </b-row>
      <b-row>
        <b-col md="6">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Company Name :
            </dt>
            <dd class="col-sm-7">
              {{ basicInfo.company_name }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              <!-- 国家区域 -->
              Jurisdiction :
            </dt>
            <dd class="col-sm-7">
              {{ currentCountryAndRegions.value }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Company Type :
            </dt>
            <dd class="col-sm-7">
              {{ CompanyTypes[basicInfo.company_type] }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Contact Phone :
            </dt>
            <dd class="col-sm-7">
              {{ basicInfo.contact_phone }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Registered Address :
            </dt>
            <dd class="col-sm-7">
              {{ basicInfo.registered_address }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Is a Listed Company? :
            </dt>
            <dd class="col-sm-7">
              {{ basicInfo.is_listed_company === 1 ? 'Yes' : basicInfo.is_listed_company === 0 ? 'No' : '' }}
            </dd>
          </dl>

          <dl class="row">
            <dt class="col-sm-5 text-right">
              Licensed Type :
            </dt>
            <dd class="col-sm-7">
              {{ lecenseAndKeyPerson.licenseType ? licenseTypeBean[lecenseAndKeyPerson.licenseType] : '-' }}
            </dd>
          </dl>
          <dl v-if="basicInfo.certificate_of_incorporation_file_list" class="row">
            <dt class="col-sm-5 text-right dt-select-label">
              Certificate of Incorporation :
            </dt>
            <dd class="col-sm-7">
              <b-button v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                title:'Certificate of Incorporation', list:basicInfo.certificate_of_incorporation_file_list, isSave:false, isDelete:false, isUpload:false})"
              >
                View
              </b-button>
              <!-- {{ basicInfo.certificate_of_incorporation_file_list }} -->
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right dt-select-label">
              Business Registration :
            </dt>
            <dd class="col-sm-7">
              <!-- {{ basicInfo.business_registration_file_list }} -->
              <b-button v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                title:'Business Registration', list:basicInfo.business_registration_file_list, isSave:false, isDelete:false, isUpload:false})"
              >
                View
              </b-button>
            </dd>
          </dl>
          <dl v-if="basicInfo.aml_questionnaire_list" class="row">
            <dt class="col-sm-5 text-right dt-select-label">
              AML Questionnaire :
            </dt>
            <dd class="col-sm-7">
              <!-- {{ basicInfo.aml_questionnaire_list }} -->
              <b-button v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                title:'AML Questionnaire', list:basicInfo.aml_questionnaire_list, isSave:false, isDelete:false, isUpload:false})"
              >
                View
              </b-button>
            </dd>
          </dl>
        </b-col>
        <b-col md="6">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              KYC Creation Time :
            </dt>
            <dd class="col-sm-7">
              {{ basicInfo.createTime }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Company Registration Number :
            </dt>
            <dd class="col-sm-7">
              {{ basicInfo.registration_number }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Incorporation Date :
            </dt>
            <dd class="col-sm-7">
              {{ basicInfo.incorporation_date }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Contact Email :
            </dt>
            <dd class="col-sm-7">
              {{ basicInfo.contact_email }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Correspondence Address :
            </dt>
            <dd class="col-sm-7">
              {{ basicInfo.correspondence_address }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Held a License?
            </dt>
            <dd class="col-sm-7">
              {{ basicInfo.is_had_license === 1 ? 'Yes' : basicInfo.is_had_license === 0 ? 'No' : '' }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right dt-select-label">
              License :
            </dt>
            <dd class="col-sm-7">
              <b-button v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                title:'License', list:basicInfo.license_file_list, isSave:false, isDelete:false, isUpload:false})"
              >
                View
              </b-button>
            </dd>
          </dl>
          <dl v-if="basicInfo.articles_of_association_list" class="row">
            <dt class="col-sm-5 text-right dt-select-label">
              Articles of Association :
            </dt>
            <dd class="col-sm-7">
              <!-- {{ basicInfo.articles_of_association_list }} -->
              <b-button v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                title:'Articles of Association', list:basicInfo.articles_of_association_list, isSave:false, isDelete:false, isUpload:false})"
              >
                View
              </b-button>
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right dt-select-label">
              NAR1 :
            </dt>
            <dd class="col-sm-7">
              <!-- {{ basicInfo.nar1_file_list }} -->
              <b-button v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                title:'NAR1', list:basicInfo.nar1_file_list, isSave:false, isDelete:false, isUpload:false})"
              >
                View
              </b-button>
            </dd>
          </dl>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        class="download-kyc-detail"
        @click="downloadKYCDetail"
      >
        Download
      </b-button>
    </b-card>

    <b-card title="Business Info">
      <b-table
        class="position-relative"
        responsive
        :fields="businessInfoFields"
        :items="currentBusinessInfoList"
        primary-key="trade_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(Attachment)="data">
          <b-button v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
            title:'Raider: Origin', list:data.item.app_pic_list, isSave:true, isDelete:true, isUpload:true, name: 'appPicList', appNo: data.item.app_no})"
          >
            View
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBusinessInfo"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePagination"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card title="Bank Info">
      <b-table
        class="position-relative"
        responsive
        :fields="bankInfoFields"
        :items="bankInfo"
        primary-key="trade_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      />
    </b-card>
    <b-card title="Key Personnel Info">
      <p>Director</p>
      <b-table
        class="position-relative"
        responsive
        :fields="directorFields"
        :items="directorList"
        primary-key="trade_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(id_type)="data">
          {{ idTypeOptionsBean[data.item.id_type] }}
        </template>
        <template #cell(statement_of_good_conduct)="data">
          {{ commonOptionsBean[data.item.statement_of_good_conduct] }}
        </template>
        <template #cell(id_proof_file_list)="data">
          <b-button v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
            title:'ID Proof', list:data.item.id_proof_file_list, isSave:false, isDelete:false, isUpload:false})"
          >
            View
          </b-button>
        </template>
        <template #cell(address_proof_file_list)="data">
          <b-button v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
            title:'Address Proof', list:data.item.address_proof_file_list, isSave:false, isDelete:false, isUpload:false})"
          >
            View
          </b-button>
        </template>
      </b-table>
      <p>Beneficiary</p>
      <b-table
        class="position-relative"
        responsive
        :fields="directorFields"
        :items="beneficiaryList"
        primary-key="trade_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(id_type)="data">
          {{ idTypeOptionsBean[data.item.id_type] }}
        </template>
        <template #cell(statement_of_good_conduct)="data">
          {{ commonOptionsBean[data.item.statement_of_good_conduct] }}
        </template>
        <template #cell(id_proof_file_list)="data">
          <b-button v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
            title:'ID Proof', list:data.item.id_proof_file_list, isSave:false, isDelete:false, isUpload:false})"
          >
            View
          </b-button>
        </template>
        <template #cell(address_proof_file_list)="data">
          <b-button v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
            title:'Address Proof', list:data.item.address_proof_file_list, isSave:false, isDelete:false, isUpload:false})"
          >
            View
          </b-button>
        </template>
      </b-table>
      <b-row>
        <b-col md="6">
          <dl class="row">
            <dt class="col-sm-9">
              Compliance Questionnaire
            </dt>
            <dd class="col-sm-3">
              <!-- {{ tradeNo }} -->
            </dd>
          </dl>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <dl class="row">
            <dt class="col-sm-10">
              Is the company or any of its officers, directors, direct or indirect shareholders or ultimate actual beneficiaries involved in any sanctions list?
            </dt>
            <dd class="col-sm-2">
              {{ complianceQuestionnaire.question_a === 1 ? 'Yes': complianceQuestionnaire.question_a === 0 ?'no' : '' }}
            </dd>
          </dl>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <dl class="row">
            <dt class="col-sm-10">
              Is the company or any of its officers, directors, direct or indirect shareholders or ultimate actual beneficiaries involved under the laws of any country, region or territory that is the target of comprehensive, country- or territory-wide Sanctions?
            </dt>
            <dd class="col-sm-2">
              {{ complianceQuestionnaire.question_b === 1 ? 'Yes':complianceQuestionnaire.question_b === 0 ?'no' : '' }}
            </dd>
          </dl>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <dl class="row">
            <dt class="col-sm-10">
              Is the company directly or indirectly owned or controlled by any sanctioned or designated person on the sanctions list, or acting as a representative for its interests?
            </dt>
            <dd class="col-sm-2">
              {{ complianceQuestionnaire.question_c === 1 ? 'Yes':complianceQuestionnaire.question_c === 0 ?'no' : '' }}
            </dd>
          </dl>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <dl class="row">
            <dt class="col-sm-10">
              Has the company or any of its officers, directors, direct or indirect shareholders or ultimate beneficiaries have any business dealings with any Pagsmile’s officers, directors or employees in the past 12 months?
            </dt>
            <dd class="col-sm-2">
              {{ complianceQuestionnaire.question_d === 1 ? 'Yes':complianceQuestionnaire.question_d === 0 ?'no' : '' }}
            </dd>
          </dl>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <dl class="row">
            <dt class="col-sm-10">
              Is the company or any of its officers, directors, direct or indirect shareholders or ultimate actual beneficiaries involved in any sanctions list?
            </dt>
            <dd class="col-sm-2">
              {{ complianceQuestionnaire.question_e === 1 ? 'Yes':complianceQuestionnaire.question_e === 0 ?'no' : '' }}
            </dd>
          </dl>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Compliance Review">
      <fieldset>
        <validation-observer ref="kycAdminRules">
          <b-row>
            <b-col md="6">
              <dl class="row">
                <dt class="col-sm-10">
                  Review Result
                </dt>
                <dd class="col-sm-2">
                  <!-- {{ tradeNo }} -->
                </dd>
              </dl>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  Risk Level
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="Risk Level"
                    rules="required"
                  >
                    <v-select
                      v-model="reviewResult.riskLevel"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="riskLevelOptions"
                      :clearable="false"
                      :searchable="false"
                      class="w-100"
                      :reduce="val => val.value"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Risk Level"
                      :disabled="can_edit===0?true:false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  DD Review
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="DD Review"
                    rules="required"
                  >
                    <v-select
                      v-model="reviewResult.reviewResult"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="reviewResultOptions"
                      :clearable="false"
                      :searchable="false"
                      class="w-100"
                      :reduce="val => val.value"
                      :state="errors.length > 0 ? false:null"
                      placeholder="DD Review"
                      :disabled="can_edit===0?true:false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  Remark
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="Remark"
                    rules="required"
                  >
                    <b-form-input
                      id="refund-trade-no"
                      v-model="reviewResult.remark"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Remark"
                      :disabled="can_edit===0?true:false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </dd>
              </dl>
            </b-col>
            <b-col md="6">
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  Risk Level Description
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="Risk Level Description"
                    rules="required"
                  >
                    <b-form-input
                      id="refund-trade-no"
                      v-model="reviewResult.riskLevelDesc"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Risk Level Description"
                      :disabled="can_edit===0?true:false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  DD Review Description
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="DD Review Description"
                    rules="required"
                  >
                    <b-form-input
                      id="refund-trade-no"
                      v-model="reviewResult.reviewResultDesc"
                      :state="errors.length > 0 ? false:null"
                      placeholder="DD Review Description"
                      :disabled="can_edit===0?true:false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </dd>
              </dl>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <dl class="row">
                <dt class="col-sm-10 dt-select-label">
                  License & Key Personnel Checking
                </dt>
                <dd class="col-sm-2">
                  <!-- {{ tradeNo }} -->
                </dd>
              </dl>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  License Type
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="License Type"
                    rules="required"
                  >
                    <v-select
                      v-model="lecenseAndKeyPerson.licenseType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="licenseTypeOptions"
                      :clearable="false"
                      :searchable="false"
                      class="w-100"
                      :reduce="val => val.value"
                      :state="errors.length > 0 ? false:null"
                      placeholder="License Type"
                      :disabled="can_edit===0?true:false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </dd>
              </dl>
            </b-col>
            <b-col md="6">
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  License Attachment
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="License Attachment"
                    rules="required"
                  >
                    <!-- placeholder="License Attachment" -->
                    <b-button v-model="lecenseAndKeyPerson.licenseAttachmentList" v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                      title:'License Attachment', list:lecenseAndKeyPerson.licenseAttachmentList, isSave:false, isDelete:can_edit===1?true:false, isUpload:can_edit===1, name: 'licenseAttachmentList'})"
                    >
                      {{ can_edit===1? 'upload':'view' }}
                    </b-button>
                    <p>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </p>
                  </validation-provider>
                </dd>
              </dl>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label license-description">
                  License Description
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="License Description"
                    rules="required"
                  >
                    <b-form-input
                      id="refund-trade-no"
                      v-model="lecenseAndKeyPerson.licenseDesc"
                      :state="errors.length > 0 ? false:null"
                      placeholder="License Description"
                      :disabled="can_edit===0?true:false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </dd>
              </dl>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  Is there PEP?
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="Is there PEP?"
                    rules="required"
                  >
                    <v-select
                      v-model="lecenseAndKeyPerson.isPEP"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="commonOptions"
                      :clearable="false"
                      :searchable="false"
                      class="w-100"
                      :reduce="val => val.value"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Is there PEP?"
                      :disabled="can_edit===0?true:false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  Is there Sanction?
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="Is there Sanction?"
                    rules="required"
                  >
                    <v-select
                      v-model="lecenseAndKeyPerson.isSanction"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="commonOptions"
                      :clearable="false"
                      :searchable="false"
                      class="w-100"
                      :reduce="val => val.value"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Is there Sanction?"
                      :disabled="can_edit===0?true:false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  Key Personnel Description
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="Key Personnel Description"
                    rules="required"
                  >
                    <b-form-input
                      id="refund-trade-no"
                      v-model="lecenseAndKeyPerson.keyPersonnelDesc"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Key Personnel Description"
                      :disabled="can_edit===0?true:false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </dd>
              </dl>
            </b-col>
            <b-col md="6">
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  PEP Proof
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="PEP Proof"
                    rules="required"
                  >
                    <!-- placeholder="PEP Proof" -->
                    <b-button v-model="lecenseAndKeyPerson.pepProofList" v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                      title:'PEP Proof', list:lecenseAndKeyPerson.pepProofList, isSave:false, isDelete:can_edit===1?true:false, isUpload:can_edit===1, name: 'pepProofList'})"
                    >
                      {{ can_edit===1? 'upload':'view' }}
                    </b-button>
                    <p><small class="text-danger">{{ errors[0] }}</small></p>
                  </validation-provider>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  Sanction Proof
                </dt>
                <dd class="col-sm-7">
                  <validation-provider
                    #default="{ errors }"
                    name="Sanction Proof"
                    rules="required"
                  >
                    <!-- placeholder="Sanction Proof" -->
                    <b-button v-model="lecenseAndKeyPerson.sanctionProofList" v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                      title:'Sanction Proof', list:lecenseAndKeyPerson.sanctionProofList, isSave:false, isDelete:can_edit===1?true:false, isUpload:can_edit===1, name: 'sanctionProofList'})"
                    >
                      {{ can_edit===1? 'upload':'view' }}
                    </b-button>
                    <p><small class="text-danger">{{ errors[0] }}</small></p>
                  </validation-provider>
                </dd>
              </dl>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <dl class="row">
                <dt class="col-sm-10 dt-select-label">
                  Other Info
                </dt>
                <dd class="col-sm-2">
                  <!-- {{ tradeNo }} -->
                </dd>
              </dl>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  Sources of Funds
                </dt>
                <dd class="col-sm-7">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Sources of Funds"
                    rules="required"
                  > -->
                  <!-- placeholder="Sources of Funds" -->
                  <b-button v-model="otherInfo.sourcesOfFundsList" v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                    title:'Sources of Funds', list:otherInfo.sourcesOfFundsList, isSave:false, isDelete:can_edit===1?true:false, isUpload:can_edit===1, name: 'sourcesOfFundsList'})"
                  >
                    {{ can_edit===1? 'upload':'view' }}
                  </b-button>
                  <!-- <p><small class="text-danger">{{ errors[0] }}</small></p>
                  </validation-provider> -->
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  Register of Shareholders
                </dt>
                <dd class="col-sm-7">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Register of Shareholders"
                    rules="required"
                  > -->
                  <!-- placeholder="Register of Shareholders" -->
                  <b-button v-model="otherInfo.registerOfShareholdersList" v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                    title:'Register of Shareholders', list:otherInfo.registerOfShareholdersList, isSave:false, isDelete:can_edit===1?true:false, isUpload:can_edit===1, name: 'registerOfShareholdersList'})"
                  >
                    {{ can_edit===1? 'upload':'view' }}
                  </b-button>
                  <!-- <p><small class="text-danger">{{ errors[0] }}</small></p>
                  </validation-provider> -->
                </dd>
              </dl>
            </b-col>
            <b-col md="6">
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  Ownership Structure Chart
                </dt>
                <dd class="col-sm-7">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Ownership Structure Chart"
                    rules="required"
                  > -->
                  <!-- placeholder="Ownership Structure Chart" -->
                  <b-button v-model="otherInfo.ownershipStructureChartList" v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                    title:'Ownership Structure Chart', list:otherInfo.ownershipStructureChartList, isSave:false, isDelete:can_edit===1?true:false, isUpload:can_edit===1, name: 'ownershipStructureChartList'})"
                  >
                    {{ can_edit===1? 'upload':'view' }}
                  </b-button>
                  <!-- <p><small class="text-danger">{{ errors[0] }}</small></p>
                  </validation-provider> -->
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-5 text-right dt-select-label">
                  Register of Directors
                </dt>
                <dd class="col-sm-7">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Register of Directors"
                    rules="required"
                  > -->
                  <!-- placeholder="Register of Directors" -->
                  <b-button v-model="otherInfo.registerOfDirectorsList" v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
                    title:'Register of Directors', list:otherInfo.registerOfDirectorsList, isSave:false, isDelete:can_edit===1?true:false, isUpload:can_edit===1, name: 'registerOfDirectorsList'})"
                  >
                    {{ can_edit===1? 'upload':'view' }}
                  </b-button>
                  <!-- <p><small class="text-danger">{{ errors[0] }}</small></p>
                  </validation-provider> -->
                </dd>
              </dl>
            </b-col>
          </b-row>
          <b-button
            v-if="can_edit===0?false:true"
            variant="primary"
            class="submit-review"
            @click="submitReview"
          >
            <span class="align-middle">Submit Review</span>
          </b-button>
          <b-button
            v-if="(can_edit===0 && reviewResult.reviewResult==2) ? true : false"
            variant="primary"
            class="submit-review"
            :disabled="is_returned === 1"
            @click="reEdit"
          >
            <span class="align-middle">Authorized merchants to re-edit</span>
          </b-button>
        </validation-observer>
      </fieldset>
    </b-card>
    <b-card title="Operation Log">
      <b-table
        class="position-relative"
        responsive
        :fields="operationLogFields"
        :items="operationLogList"
        primary-key="trade_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(attachment_list)="data">
          <b-button v-b-modal.modal-multi-1 v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="showFileListModal({
            title:'Attachment', list:data.item.attachment_list, isSave:false, isDelete:false, isUpload:false})"
          >
            View
          </b-button>
        </template>
      </b-table>
    </b-card>
    <b-overlay
      :show="loading"
      no-wrap
    />
    <b-modal
      id="modal-multi-1"
      ref="file-modal"
      size="lg"
      :title="modalTitle"
      no-stacking
      hide-footer
      ok-only
      ok-title="Accept"
      @close="fileModalClose"
      @hide="fileModalClose"
    >
      <kyc-file-modal ref="kycFileModal" :file-list="fileList" :is-save="isSave" :is-delete="isDelete" :is-upload="isUpload" :return-key="modalName" @saveFileList="saveFileList" @cancel="cancel">
        <slot name="main" />
      </kyc-file-modal>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BPagination, BTable, BFormInput, BButton, BOverlay,
} from 'bootstrap-vue'
// import {
//   BCard, BRow, BFormGroup, BCol, BButton, BTabs, BTab, BCardText, BFormTextarea, BFormFile, BLink, BFormInput, BTable,BFormFile
// } from 'bootstrap-vue'
import moment from 'moment-timezone'
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { showToast } from '@/libs/tool'
import KycFileModal from './KycFileModal.vue'
// import { showAlert, showToast } from '@/libs/tool'
import useMerchantJs from './merchants'

const {
  fetchKycDetail,
  submitKycDetail,
  getCountryAndRegions,
  downloadKYCDetail,
  saveAppPics,
  kycReturnData,
} = useMerchantJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BPagination,
    BTable,
    vSelect,
    BFormInput,
    // BFormFile,
    BButton,
    BOverlay,
    KycFileModal,
    // BTabs,
    // BTab,
    // BCardText,
    // BFormTextarea,
    // BFormFile,
    // BLink,
    // vSelect,
    // BFormInput,
    // BFormGroup,
    // BTable,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  filters: {
    timestampFilter(timestamp) {
      return moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
  },
  data() {
    return {
      // 渲染数据
      can_edit: false,
      is_returned: 0,
      merchantNo: '',
      basicInfo: {},
      // 处理 businessInfo
      businessInfo: [],
      currentBusinessInfoList: [],
      businessInfoFields: [
        { key: 'app_name', label: 'App Name' },
        { key: 'website', label: 'Website' },
        { key: 'industry', label: 'Industry' },
        { key: 'complaint_email', label: 'Customer Service Number' },
        { key: 'Attachment', label: 'Attachment' },
      ],
      // 分页
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      currentPage: 1,
      totalBusinessInfo: 0,
      perPage: 6,
      // 处理 bankInfo
      bankInfo: [],
      bankInfoFields: [
        { key: 'account_number', label: 'Account Number' },
        { key: 'account_name', label: 'Account Name' },
        { key: 'bank_name', label: 'Bank Full Name' },
        { key: 'bank_address', label: 'Bank Address' },
        { key: 'swift', label: 'SWIFT' },
        { key: 'iban', label: 'IBAN' },
        { key: 'bsb_number', label: 'BSB Number' },
        { key: 'aba', label: 'ABA' },
      ],
      directorList: [],
      directorFields: [ // 未使用字段 is_crime/is_pep/is_sanction
        { key: 'name', label: 'Name' },
        { key: 'position', label: 'Position' },
        { key: 'id_type', label: 'ID Type' },
        { key: 'id_number', label: 'ID Number' },
        { key: 'statement_of_good_conduct', label: 'Statement of Good Conduct' },
        { key: 'residence_address', label: 'Residence Address' },
        { key: 'id_proof_file_list', label: 'ID Proof' },
        { key: 'address_proof_file_list', label: 'Address Proof' },
      ],
      beneficiaryList: [],
      complianceQuestionnaire: {},
      operationLogList: [],
      operationLogFields: [
        { key: 'account', label: 'Account' },
        { key: 'update_time', label: 'Update Time' },
        { key: 'review_result', label: 'DD Review' },
        { key: 'remark', label: 'Operation Remark' },
        { key: 'attachment_list', label: 'Attachment' },
      ],
      required,
      email,
      riskLevelOptions: [
        { label: 'Extremely High Risk', value: '4' },
        { label: 'High Risk', value: '3' },
        { label: 'Medium Risk', value: '2' },
        { label: 'Low Risk', value: '1' },
        // { label: 'not select', value: '9' },
      ],
      reviewResultOptions: [
        { label: 'Approved', value: '1' },
        { label: 'Rejected', value: '2' },
        // { label: 'None', value: '9' },
      ],
      licenseTypeOptions: [
        { label: 'Offshore License', value: '4' },
        { label: 'Licensed', value: '1' },
        { label: 'Unlicensed', value: '2' },
        { label: 'Not Required', value: '3' },
        // { label: 'None', value: '9' },
      ],
      licenseTypeBean: {
        1: 'Licensed',
        2: 'Unlicensed',
        3: 'Not Required',
        4: 'Offshore License',
      },
      commonOptions: [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '0' },
      ],
      reviewResult: {
        remark: '',
        reviewResult: '',
        reviewResultDesc: '',
        riskLevel: '',
        riskLevelDesc: '',
      },
      lecenseAndKeyPerson: {
        isPEP: '',
        isSanction: '',
        keyPersonnelDesc: '',
        licenseAttachmentList: [],
        licenseDesc: '',
        licenseType: '',
        sanctionProofList: [],
        pepProofList: [],
      },
      otherInfo: {
        ownershipStructureChartList: [],
        registerOfDirectorsList: [],
        registerOfShareholdersList: [],
        sourcesOfFundsList: [],
      },
      licenseAttachmentFiles: null,
      pepProofFiles: null,
      sanctionProofFiles: null,
      sourcesOfFundsFiles: null,
      registerOfShareholdersFiles: null,
      ownershipFiles: null,
      registerOfDirectorsFiles: null,
      loading: false,
      // modal
      modalTitle: '',
      fileList: [],
      isSave: false,
      isDelete: false,
      isUpload: false,
      countryAndRegions: [],
      modalName: '',
      currentAppNo: '',
      CompanyTypes: {
        1: 'Corporation',
        2: 'SoleProprietorship',
        3: 'Limited',
      },
      idTypeOptionsBean: {
        1: 'ID',
        2: 'Passport',
        3: "Driver's License",
        4: 'Other',
      },
      commonOptionsBean: {
        0: 'No',
        1: 'Yes',
      },
    }
  },
  computed: {
    currentCountryAndRegions() {
      if (this.basicInfo && this.basicInfo.registered_country_or_region) {
        return this.countryAndRegions.length > 0 && this.countryAndRegions.filter(value => value.id === this.basicInfo.registered_country_or_region)[0]
      }
      return ''
    },
  },
  watch: {
    // licenseAttachmentFiles(value) {
    // },
  },
  created() {
    const that = this
    getCountryAndRegions(
      list => {
        that.countryAndRegions = that.updateCountryAndRegions(list)
      },
      fail => {
        showToast(this, 'Warning', `fetch country and regions with ${fail}.`)
      },
    )
    this.initKycDetail()
  },
  methods: {
    initKycDetail() {
      const that = this
      that.loading = true
      that.$showLoading()
      fetchKycDetail(
        data => {
          that.$hideLoading()
          that.loading = false
          const {
            can_edit,
            is_returned,
            merchant_no,
            base_info,
            license_info,
            upload_attachment_info,
            business_info_page,
            bank_info,
            director_list,
            beneficiary_list,
            compliance_questionnaire,
            operation_log_list,
            create_time,
            compliance_review_info, // subbmit信息
          } = data
          // eslint-disable-next-line camelcase
          that.can_edit = can_edit
          // eslint-disable-next-line camelcase
          that.is_returned = is_returned
          // eslint-disable-next-line camelcase
          that.merchantNo = merchant_no
          that.basicInfo = Object.assign(base_info, license_info, upload_attachment_info)
          // eslint-disable-next-line camelcase
          that.basicInfo.createTime = create_time
          // eslint-disable-next-line camelcase
          that.businessInfo = business_info_page.data_list
          that.totalBusinessInfo = business_info_page.total
          that.changeDataMeta()
          that.getCurrentBusinessInfoList()

          // eslint-disable-next-line camelcase
          that.bankInfo = !!bank_info && new Array(bank_info)
          // eslint-disable-next-line camelcase
          that.directorList = director_list
          // eslint-disable-next-line camelcase
          that.beneficiaryList = beneficiary_list
          // eslint-disable-next-line camelcase
          that.complianceQuestionnaire = compliance_questionnaire || {}
          // eslint-disable-next-line camelcase
          that.operationLogList = operation_log_list
          // eslint-disable-next-line camelcase
          if (compliance_review_info) {
            that.renderComplianceReviewInfo(compliance_review_info)
          }
        },
        fail => {
          that.$hideLoading()
          that.loading = false
          showToast(that, 'Warning', `fetch detail with ${fail}.`)
        },
        {
          merchant_no: that.$route.query.merchant_no,
          id: that.$route.query.id,
        },
      )
    },
    changeDataMeta() {
      this.dataMeta.from = this.currentPage * this.perPage - (this.perPage - 1)
      this.dataMeta.to = this.totalBusinessInfo - this.currentPage * this.perPage > 0 ? this.currentPage * this.perPage : this.totalBusinessInfo
      this.dataMeta.of = this.totalBusinessInfo
    },
    getCurrentBusinessInfoList() {
      if (this.businessInfo.length > 0) {
        const begin = this.perPage * (this.currentPage - 1)
        const end = this.perPage * this.currentPage
        this.currentBusinessInfoList = this.businessInfo.slice(begin, end)
      }
    },
    changePagination(num) {
      this.currentPage = num
      this.changeDataMeta()
      this.getCurrentBusinessInfoList()
    },
    getReviewData() {
      const {
        isPEP,
        isSanction,
        keyPersonnelDesc,
        licenseDesc,
        licenseType,
        licenseAttachmentList,
        sanctionProofList,
        pepProofList,
      } = this.lecenseAndKeyPerson
      const {
        ownershipStructureChartList,
        registerOfDirectorsList,
        registerOfShareholdersList,
        sourcesOfFundsList,
      } = this.otherInfo
      const {
        remark,
        reviewResult,
        reviewResultDesc,
        riskLevel,
        riskLevelDesc,
      } = this.reviewResult

      const reviewData = {
        license_and_key_person: {
          is_pep: Number(isPEP),
          is_sanction: Number(isSanction),
          key_personnel_desc: keyPersonnelDesc,
          license_attachment_list: licenseAttachmentList,
          license_desc: licenseDesc,
          license_type: Number(licenseType),
          sanction_proof_list: sanctionProofList,
          pep_proof_list: pepProofList,
        },
        other_info: {
          ownership_structure_chart_list: ownershipStructureChartList,
          register_of_directors_list: registerOfDirectorsList,
          register_of_shareholders_list: registerOfShareholdersList,
          sources_of_funds_list: sourcesOfFundsList,
        },
        review_result: {
          remark,
          review_result: Number(reviewResult),
          review_result_desc: reviewResultDesc,
          risk_level: Number(riskLevel),
          risk_level_desc: riskLevelDesc,
        },
      }
      return reviewData
    },
    submitReview() {
      const that = this
      this.$refs.kycAdminRules.validate().then(success => {
        if (success) {
          that.$showLoading()
          that.loading = true
          const params = {}
          params.id = that.merchantNo
          params.data = that.getReviewData()
          submitKycDetail(
            () => {
              // eslint-disable-next-line no-restricted-globals
              location.reload()
              showToast(that, 'Success', 'Saved')
            },
            fail => {
              that.$hideLoading()
              that.loading = false
              showToast(that, 'Warning', `${fail}`)
            },
            params,
          )
        } else {
          showToast(that, 'Warning', 'Please enter valid information.')
        }
      })
    },
    reEdit() {
      const that = this
      that.$showLoading()
      that.loading = true
      kycReturnData(
        () => {
          that.is_returned = 1
          that.$hideLoading()
          that.loading = false
          showToast(that, 'Success', 're-edit success')
        },
        fail => {
          that.$hideLoading()
          that.loading = false
          showToast(that, 'Warning', `${fail}.`)
        },
        { id: that.$route.query.merchant_no },
      )
    },
    renderComplianceReviewInfo(reviewInfo) {
      if (!reviewInfo) return
      const { license_and_key_person, other_info, review_result } = reviewInfo
      // eslint-disable-next-line camelcase
      if (license_and_key_person) {
        this.lecenseAndKeyPerson = {
          isPEP: this.commonOptions.filter(value => license_and_key_person.is_pep === Number(value.value))[0].value,
          isSanction: this.commonOptions.filter(value => license_and_key_person.is_sanction === Number(value.value))[0].value,
          keyPersonnelDesc: license_and_key_person.key_personnel_desc,
          licenseAttachmentList: license_and_key_person.license_attachment_list,
          licenseDesc: license_and_key_person.license_desc,
          licenseType: this.licenseTypeOptions.filter(value => license_and_key_person.license_type === Number(value.value))[0].value,
          sanctionProofList: license_and_key_person.sanction_proof_list,
          pepProofList: license_and_key_person.pep_proof_list,
        }
      }
      // eslint-disable-next-line camelcase
      if (other_info) {
        this.otherInfo = {
          ownershipStructureChartList: other_info.ownership_structure_chart_list,
          registerOfDirectorsList: other_info.register_of_directors_list,
          registerOfShareholdersList: other_info.register_of_shareholders_list,
          sourcesOfFundsList: other_info.sources_of_funds_list,
        }
      }
      // eslint-disable-next-line camelcase
      if (review_result) {
        this.reviewResult = {
          remark: review_result.remark,
          reviewResult: this.reviewResultOptions.filter(value => review_result.review_result === Number(value.value))[0].value,
          reviewResultDesc: review_result.review_result_desc,
          riskLevel: this.riskLevelOptions.filter(value => review_result.risk_level === Number(value.value))[0].value,
          riskLevelDesc: review_result.risk_level_desc,
        }
      }
    },
    updateCountryAndRegions(data) {
      const countryList = []
      Object.keys(data).forEach(item => {
        const bean = {
          id: data[item],
          value: item,
        }
        countryList.push(bean)
      })
      return countryList
    },
    /**
     * 展示弹框数据
     */
    showFileListModal(item) {
      const {
        title, list, isSave, isDelete, isUpload, name, appNo,
      } = item
      this.modalTitle = title || ''
      this.fileList = list || ''
      this.isSave = isSave
      this.isDelete = isDelete
      this.isUpload = isUpload
      this.modalName = name || ''
      if (appNo) {
        this.currentAppNo = appNo
      }
    },
    downloadKYCDetail() {
      const that = this
      that.$showLoading()
      that.loading = true
      downloadKYCDetail(
        url => {
          if (url) {
            that.loading = false
            that.$hideLoading()
            const aFile = document.createElement('a')
            aFile.href = url
            aFile.target = '_blank'
            aFile.style.display = 'none'
            document.body.appendChild(aFile)
            aFile.click()
            aFile.remove()
          } else {
            that.loading = false
            that.$hideLoading()
            showToast(that, 'Warning', 'Download without url')
          }
        },
        fail => {
          that.loading = false
          that.$hideLoading()
          showToast(that, 'Warning', `Download fail with ${fail}`)
        },
        {
          merchant_no: that.$route.query.merchant_no,
          id: that.$route.query.id,
        },
      )
    },
    fileModalClose() {
      const files = this.$refs.kycFileModal.getFiles()
      const key = Object.keys(files)[0]
      if (key === 'licenseAttachmentList') {
        this.lecenseAndKeyPerson.licenseAttachmentList = files.licenseAttachmentList
      } else if (key === 'pepProofList') {
        this.lecenseAndKeyPerson.pepProofList = files.pepProofList
      } else if (key === 'sanctionProofList') {
        this.lecenseAndKeyPerson.sanctionProofList = files.sanctionProofList
      } else if (key === 'sourcesOfFundsList') {
        this.otherInfo.sourcesOfFundsList = files.sourcesOfFundsList
      } else if (key === 'registerOfShareholdersList') {
        this.otherInfo.registerOfShareholdersList = files.registerOfShareholdersList
      } else if (key === 'ownershipStructureChartList') {
        this.otherInfo.ownershipStructureChartList = files.ownershipStructureChartList
      } else if (key === 'registerOfDirectorsList') {
        this.otherInfo.registerOfDirectorsList = files.registerOfDirectorsList
      } else if (key === 'appPicList') {
        // this.saveAppPics(files.appPicList)
      // eslint-disable-next-line no-empty
      } else {}
    },
    saveFileList(files) {
      const key = Object.keys(files)[0]
      if (key === 'appPicList') {
        this.saveAppPics(files.appPicList, 'save')
      }
    },
    saveAppPics(list, type) {
      const that = this
      that.$showLoading()
      saveAppPics(
        () => {
          that.$hideLoading()
          that.businessInfo.forEach(business => {
            // eslint-disable-next-line no-param-reassign
            business.app_pic_list = business.app_no === that.currentAppNo ? list : []
          })
          that.currentAppNo = ''
          if (type === 'save') {
            that.$refs['file-modal'].toggle()
          }
          showToast(that, 'Success', 'Saved')
        },
        fail => {
          that.$hideLoading()
          showToast(that, 'Warning', `Save App Pics fail with ${fail}`)
        },
        {
          id: this.currentAppNo,
          list,
        },
      )
    },
    cancel() {
      this.$refs['file-modal'].toggle()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.dt-select-label {
  line-height: 40px;
}
// .dt-select-label.license-description {
//   // width: 22rem;
//   // margin-right: 0.75rem;
// }
.submit-review {
    position: absolute;
    top: 12px;
    right: 20px;
}
.download-kyc-detail {
  position: absolute;
  right: 50px;
  top: 20px;
}
</style>
